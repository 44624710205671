<template>
  <div class="container list">
    <div v-for="(item,index) in dataList" :key="index" class="item" @click="goDetail(item.id)">
      <div class="top flex">
        <img :src="require('../../assets/img/icon'+item.status+'.png')" alt="">
        <div class="title flex">
          <p>答题题目：{{item.title}}</p>
          <div class="more flex">
            <div class="dot"
              :style="'background:'+(item.status==1?'#1890FF':item.status==2?'#5FDDC3':'rgba(0, 0, 0, 0.25)')"></div>
            <span class="text">{{item.status==1?'进行中':item.status==2?'已完成':'未开始'}}</span>
            <!-- 1: 开始 2: 结束 0:未开始 -->
            <RightOutlined />
          </div>
        </div>
      </div>
      <div class="detail flex">
        <div>
          <p v-if="item.status!=0">答题开始时间：{{item.startTime}}</p>
          <p v-if="item.status==2">答题结束时间：{{item.endTime}}</p>
          <p>题目数量：{{item.questionCount}}</p>
        </div>
        <div>
          <p v-if="item.status!=0">持续时间<span>{{item.durationTime}}</span></p>
          <p v-if="item.status!=0">已有<span>{{item.paperNum}}位</span>用户参与答题</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { computed, ref, reactive, onMounted } from 'vue'
  import { RightOutlined } from '@ant-design/icons-vue';
  import { getList } from '@/api/answer'
  import router from '@/router';

  const dataList = ref([])

  const goDetail = (id) => {
    router.push('/answer/detail?id=' + id)
  }

  const getTime = (time) => {
    if (time) {
      const convertedTime = new Date(time);
      convertedTime.setHours(convertedTime.getHours() + 8);
      const formattedTime = convertedTime.toLocaleString('zh-CN', { timeZone: 'UTC' });
      return formattedTime;
    } else {
      return ''
    }
  }

  // 当前时间
  function getCurrentTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  }

  // 计算时间差
  function getDurationTime(startTime, endTime) {
    if (!startTime && !endTime) {
      return 0
    } else {
      if (!endTime) {
        // 没有endTime，按当前时间计算
        endTime = getCurrentTime()
      }

      // 计算时间差（单位为毫秒）
      const diffInMs = Math.abs(new Date(endTime) - new Date(startTime));
      // 将时间差转换为天、小时和分钟
      const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

      return `${days}天 ${hours}小时 ${minutes}分钟`
    }
  }

  function fetch(page) {
    getList({
      _pageSize: 100,
      _page: page,
    }).then((res) => {
      res.data.forEach((item, index) => {
        for (const key in item.attributes) {
          item[key] = item.attributes[key];
        }

        item.startTime = getTime(item.startTime)
        item.endTime = getTime(item.endTime)

        // 计算持续时间
        item.durationTime = getDurationTime(item.startTime, item.endTime)
      });

      dataList.value = dataList.value.concat(res.data)

      if (res.meta.pagination.total > (100 * page)) {
        page++
        fetch(page)
      }
    })
  }

  onMounted(() => {
    fetch(1)
  })
</script>
<style lang="less" scoped>
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .list {
    display: flex;
    flex-flow: row wrap;
  }

  .item {
    width: 30%;
    min-width: 529px;
    height: 168px;
    margin: 0 32px 20px 0;
    padding: 15px 25px 14px 15px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(186, 190, 196, 0.20);
    background-image: url('../../assets/img/plate_bg.png'), linear-gradient(to bottom, #EBF2FE, #FDFEFF);
    background-size: 140px auto, 100% 100%;
    background-repeat: no-repeat;
    background-position: top;
    cursor: pointer;

    .top {

      img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }

      .title {
        flex: 1;

        p {
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          margin-bottom: 0;
        }

        .more {
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 16px;
          color: #555770;

          .dot {
            width: 8px;
            height: 8px;
            border-radius: 8px;
          }

          .text {
            margin: 0 8px 0 10px;
          }

          .anticon {
            font-size: 14px;
          }
        }
      }
    }

    .detail {
      align-items: flex-start;
      padding: 10px 0 0 45px;

      div {
        p {
          margin-bottom: 10px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 14px;
          color: #555770;

          span {
            color: #EA1818;
          }
        }

        &:nth-child(2) {
          p {
            text-align: right;
          }
        }
      }
    }
  }
</style>