<template>
  <div class="container">
    <a-table :columns="columns" :row-key="(record) => record.uuid" :data-source="data" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'status'">
          <span class="dot"
            :style="'background:'+(record.status==1?'#1890FF':record.status==2?'#5FDDC3':'rgba(0, 0, 0, 0.25)')"></span>
          {{ record.status == 0 ?'未开始':record.status == 1 ?'进行中':'已完成'}}
        </template>
        <template v-if="column.key === 'action'">
          <!-- 进行中 -->
          <span v-if="record.status == 1">
            <a-popconfirm title="确定结束该答题活动吗?" ok-text="确定" cancel-text="取消" @confirm="changeStatus(record.id,2)">
              <a>结束</a>
            </a-popconfirm>
            <a-divider type="vertical" />
            <a @click="goDetail(record.id)">答题情况</a>
            <a-divider type="vertical" />
            <a @click="exportDetail(record)">导出详情</a>
          </span>
          <!-- 未开始 -->
          <span v-else-if="record.status == 0">
            <a-popconfirm title="确定开始该答题活动吗?" ok-text="确定" cancel-text="取消" @confirm="changeStatus(record.id,1)">
              <a>开始</a>
            </a-popconfirm>
            <a-divider type="vertical" />
            <a @click="setInviteCode(record.id)">邀请码</a>
          </span>
          <!-- 已完成 -->
          <span v-else>
            <a @click="goDetail(record.id)">答题情况</a>
            <a-divider type="vertical" />
            <a @click="exportDetail(record)">导出详情</a>
          </span>
        </template>
        <template v-if="column.key === 'startTime'">
          {{ getTime(record.startTime)}}
        </template>
        <template v-if="column.key === 'endTime'">
          {{ getTime(record.endTime)}}
        </template>
      </template>
    </a-table>
    <a-modal v-model:visible="visible" title="邀请码设置" @ok="handleSubmit" @cancel="resetForm">
      <a-form @submit.prevent :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="邀请码：" v-bind="validateInfos.code">
          <a-input v-model:value="modelRef.code" placeholder="请设置您的邀请码">
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted } from 'vue'
  import { getList, examsStatus, examsInviteCode, exportAnswer } from '@/api/answer'
  import router from '@/router';
  import { Form, message } from 'ant-design-vue';

  const pwdForm = Form.useForm;

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
    },
    {
      title: '答题题目',
      dataIndex: 'title',
    },
    {
      title: '题目数量',
      dataIndex: 'questionCount',
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
      key: 'startTime'
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      key: 'endTime'
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status', // 1: 开始 2: 结束 0:未开始

    },
    {
      title: '邀请码',
      dataIndex: 'inviteCode',
    },
    {
      title: '操作',
      key: 'action',
    },
  ]

  const visible = ref(false);
  const currentId = ref(null);
  const data = ref([])

  const pagination = reactive({
    showLessItems: true,
    showQuickJumper: true,
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0
  })
  const loading = ref(false)

  const changeStatus = (id, status) => {
    examsStatus(id, status).then((res) => {
      message.success('已' + (status == 2 ? '结束' : '开始') + '该答题活动！')
      fetch();
    })
  }

  const goDetail = (id) => {
    router.push('/answer/detail?id=' + id)
  }

  const exportDetail = (record) => {
    exportAnswer(record.id).then((res) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = res.file.url;
      // downloadLink.download = record.attributes.title + '.csv';
      downloadLink.download = '急诊分诊24小时内脓毒症风险预警-答题情况-' + getCurrentDay() + '.csv'
      downloadLink.style.display = 'none';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
  }

  const getCurrentDay = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
  }

  const getTime = (time) => {
    if (time) {
      const convertedTime = new Date(time);
      convertedTime.setHours(convertedTime.getHours() + 8);
      const formattedTime = convertedTime.toLocaleString('zh-CN', { timeZone: 'UTC' });
      return formattedTime;
    } else {
      return ''
    }
  }

  const setInviteCode = (id) => {
    currentId.value = id
    visible.value = true;
  }
  const modelRef = reactive({
    code: '',
  });

  const { resetFields, validate, validateInfos } = pwdForm(
    modelRef,
    reactive({
      code: [
        {
          pattern: /^[0-9a-zA-Z]{4}$/, message: '4位，支持输入数字、字母', trigger: 'change'
        },
        {
          required: true,
          message: '请输入邀请码',
          trigger: 'change',
        },
      ],
    }),
  );

  const handleSubmit = () => {
    validate()
      .then(async res => {
        examsInviteCode(currentId.value, res.code).then((res) => {
          message.success('邀请码设置成功！')
          visible.value = false;
          resetForm();
          fetch();
        })
      })
      .catch(err => {
        console.log('error', err);
      });
  };

  const resetForm = () => {
    resetFields();
  };


  function handleTableChange(p) {
    pagination.current = p.current
    pagination.pageSize = p.pageSize
    fetch();
  }

  function fetch() {
    loading.value = true;
    getList({
      _pageSize: pagination.pageSize,
      _page: pagination.current,
    }).then((res) => {
      loading.value = false
      res.data.forEach((item, index) => {
        item.index = (pagination.current - 1) * pagination.pageSize + index + 1
        for (const key in item.attributes) {
          item[key] = item.attributes[key];
        }
      });

      data.value = res.data
      pagination.total = res.meta.pagination.total
    })
  }

  onMounted(() => {
    fetch()
  })
</script>
<style lang="less" scoped>
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }
</style>